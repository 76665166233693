import { format } from 'date-fns'
import Vue from 'vue'

Vue.filter('formatDate', value => {
  if (value) {
    return format(new Date(value), 'dd/MM/yyyy')
  }

  return format(new Date(), 'dd/MM/yyyy')
})

Vue.filter('formatDateHuman', value => {
  if (value) {
    return format(new Date(value), 'MMMM do, yyyy')
  }

  return format(new Date(), 'MMMM do, yyyy')
})


Vue.filter('formatDateTime', value => {
  if (value) {
    return format(new Date(value), 'dd/MM/yyyy HH:mm')
  }

  return format(new Date(), 'dd/MM/yyyy HH:mm')
})

Vue.filter('formatCouponRate', value => {
  if (value) {
    return `${value}%`
  }

  return '0%'
})

Vue.filter('formatCurrency', value => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'KES',
    minimumFractionDigits: 0,
  })

  return formatter.format(value)
})
