import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('@/views/pages/Transactions.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/bonds',
    name: 'bonds',
    component: () => import('@/views/pages/Bonds.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/analytics/bonds',
    name: 'bonds-analytics',
    component: () => import('@/views/pages/analytics/Bonds.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/Settings.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const easingFunctions = {
  easeInOutCubic: t => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1),
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: async to => {
    if (to.hash) {
      const targetElement = document.querySelector(to.hash)

      if (targetElement) {
        const offset = targetElement.offsetTop
        const duration = 1000
        const easing = 'easeInOutCubic'
        const start = window.pageYOffset
        const startTime = 'now' in window.performance ? performance.now() : new Date().getTime()

        const scroll = () => {
          const elapsed = 'now' in window.performance ? performance.now() - startTime : new Date().getTime() - startTime
          const progress = Math.min(elapsed / duration, 1)
          const ease = easingFunctions[easing](progress)

          window.scrollTo(0, Math.floor(start + offset * ease))

          if (progress < 1) {
            window.requestAnimationFrame(scroll)
          }
        }

        scroll()
      }
    }
  },
})

router.beforeEach((to, from, next) => {
  const { isUserAuthenticated } = store.getters
  if (to.name === 'login' && isUserAuthenticated) {
    return router.push({ path: 'dashboard' })
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isUserAuthenticated) {
      return router.replace({ path: 'login' })
    }

    return next()
  }

  return next()
})

export default router
